import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';
// See https://fontawesome.com/icons?d=gallery&s=brands,regular&m=free
// to add other icons.

const data = [
  {
    link: 'mailto:unity@jacobhthomas.com',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
